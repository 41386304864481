import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { theme } from "../../styles/theme";
import { gridSquares } from "../../styles/grid";
import { EABlockContent } from "../BlockContent";
import { SocialMediaLinks } from "../SocialMediaLinks";
import { getSocialMediaLinks } from "../../util/getSocialMediaLinks";
import { SubHeading } from "../Typography/SubHeading";
import { regular12Type } from "../../styles/typography";
import { BlogPostBodyWrapper } from "../Blog/BlogPostBodyWrapper";

const Layout = styled.div`
    width: 100%;
    padding: 0 ${(p) => p.theme.padding.pageSide};

    display: flex;

    > :not(:first-child) {
        margin-left: ${(p) => p.theme.grid.gutters.m};
    }

    @media (max-width: ${(p) => p.theme.maxWidth.teamMembersSection}) {
        flex-direction: column;

        > :not(:first-child) {
            margin-left: unset;
            margin-top: ${(p) => p.theme.grid.gutters.m};
        }
    }
`;

const TextLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > :nth-child(2) {
        margin-top: ${gridSquares(2)};
    }

    > :nth-child(3) {
        margin-top: ${(p) => p.theme.grid.gutters.m};
    }
`;

const Row = styled.div`
    width: 100%;

    position: relative;
    display: flex;
    align-items: flex-end;

    > :not(:first-child) {
        margin-left: ${(p) => p.theme.grid.gutters.s};
    }

    > :last-child {
        margin-left: auto;
    }

    @media (max-width: ${(p) => p.theme.maxWidth.pageSection}) {
        flex-direction: column;
        align-items: flex-start;

        > :not(:first-child) {
            margin-left: unset;
            margin-top: ${(p) => p.theme.grid.gutters.s};
        }

        > :last-child {
            margin-left: unset;
        }
    }
`;

const JobTitle = styled.p`
    ${regular12Type}
`;

const SocialMediaLayout = styled.div`
    position: absolute;
    right: -${gridSquares(1.25)};
    top: -${gridSquares(0.75)};

    @media (max-width: ${(p) => p.theme.maxWidth.pageSection}) {
        top: -${gridSquares(1.85)};
    }
`;

const Button = styled.button`
    font-weight: bold;
    text-decoration: underline;
`;

interface PropsType {
    member: {
        // image comes from src/pages/company.tsx -> src/components/Company/index.tsx -> src/components/Team/index.tsx
        image: any;
        linkedIn?: string;
        name: string;
        startDate: string;
        team: string;
        title: string;
        twitter?: string;
        _rawDescription: any;
    };
    onBackClick: () => void;
}

export const SelectedMember = ({ member, onBackClick }: PropsType) => (
    <Layout>
        <GatsbyImage
            image={member.image.asset.gatsbyImageData}
            alt={member.name}
            imgStyle={{
                transition: theme.transition(`transform`),
            }}
            style={{
                borderRadius: `${theme.borderRadius.l}`,
                flexShrink: 0,
                height: gridSquares(15),
                width: gridSquares(15),
            }}
        />
        <TextLayout>
            <Row>
                <SubHeading>{member.name}</SubHeading>
                <JobTitle>{member.title}</JobTitle>
                <SocialMediaLayout>
                    <SocialMediaLinks
                        links={getSocialMediaLinks(
                            member.twitter,
                            member.linkedIn
                        )}
                    />
                </SocialMediaLayout>
            </Row>
            <BlogPostBodyWrapper>
                <EABlockContent blocks={member._rawDescription} />
            </BlogPostBodyWrapper>

            <Button onClick={onBackClick}>Back to team</Button>
        </TextLayout>
    </Layout>
);
