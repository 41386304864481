import React, { useRef, useState } from "react";
import { Heading } from "../Typography/Heading";
import { sortBy, startCase, uniq } from "lodash";
import {
    AddPadding,
    PhotoButton,
    PhotoText,
    PhotoLi,
    PhotoList,
    TeamButton,
    TeamButtonLi,
    TeamButtonText,
    TeamButtonUl,
    Line,
} from "./components";
import { GatsbyImage } from "gatsby-plugin-image";
import { gridSquares } from "../../styles/grid";
import { theme } from "../../styles/theme";
import { SelectedMember } from "./SelectedMember";
import styled from "styled-components";
import { PageSectionContainer } from "../PageSection";

const AvatarImage = styled(GatsbyImage)`
    border-radius: ${theme.borderRadius.l};

    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(
        white,
        black
    ); /* This line fixes a rendering issue in Safari. The CSS property "overflow: hidden" won't work in Safari unless you add this webkit property*/
`;

interface PropsType {
    teamMembers: GatsbyTypes.CompanyPageQuery["allSanityAuthor"]["nodes"];
}

const parseTeamName = (team: string) => {
    if (team === `ceo`) {
        return `CEO`;
    }

    if (team.includes(`engineering`)) {
        return startCase(team).replace(`Engineering`, `Engineering -`);
    }

    return startCase(team);
};

const TeamSection = styled(PageSectionContainer)`
    padding-top: ${gridSquares(5)};
`;

export const Team = ({ teamMembers }: PropsType) => {
    const parentLayoutRef = useRef(null);
    const [selectedId, setSelected] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState("all");

    const teams = uniq(teamMembers.map((member) => member.team)).sort();

    const displayedMembers = teamMembers.filter((member) => {
        if (selectedTeam === "all") {
            return true;
        }

        return member.team === selectedTeam;
    });

    const selectedMember = selectedId
        ? teamMembers.find((member) => member._id === selectedId)
        : null;

    const onPortraitClick = (id: string) => {
        if (parentLayoutRef.current) {
            const offset = 40;
            window.scrollTo({
                top:
                    parentLayoutRef.current.getBoundingClientRect().top +
                    window.pageYOffset -
                    offset,
            });
        }
        setSelected(id);
    };

    const GridView = () => (
        <>
            <TeamButtonUl>
                {["all", ...teams].map((team) => (
                    <TeamButtonLi key={team}>
                        <TeamButton
                            isSelected={selectedTeam === team}
                            onClick={() => setSelectedTeam(team)}
                        >
                            <TeamButtonText isSelected={selectedTeam === team}>
                                {parseTeamName(team)}
                            </TeamButtonText>
                        </TeamButton>
                    </TeamButtonLi>
                ))}
            </TeamButtonUl>
            <Line />
            <PhotoList>
                {sortBy(displayedMembers, "name").map((member) => (
                    <PhotoLi key={member._id}>
                        <PhotoButton
                            onClick={() => onPortraitClick(member._id)}
                        >
                            <AvatarImage
                                image={member.image.asset.gatsbyImageData}
                                alt={member.name}
                                imgStyle={{
                                    transition: theme.transition(`transform`),
                                }}
                                style={{
                                    height: gridSquares(9),
                                    width: gridSquares(9),
                                }}
                            />
                            {selectedTeam !== "all" && (
                                <>
                                    <PhotoText>{member.name}</PhotoText>
                                    <PhotoText>{member.title}</PhotoText>
                                </>
                            )}
                        </PhotoButton>
                    </PhotoLi>
                ))}
            </PhotoList>
        </>
    );

    return (
        <TeamSection>
            <AddPadding>
                <Heading level="h2" levelStyle="h2">
                    Team
                </Heading>
            </AddPadding>
            {selectedMember ? (
                <SelectedMember
                    member={selectedMember}
                    onBackClick={() => setSelected(null)}
                />
            ) : (
                <GridView />
            )}
        </TeamSection>
    );
};
