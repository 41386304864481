import { gridSquares } from "../styles/grid";
import { PageSectionContainer } from "../components/PageSection";
import { Paragraph } from "./Typography/Paragraph";
import React from "react";
import styled from "styled-components";
import values from "../data/values";
import { GridGutterRows } from "./GridGutters";
import { Heading } from "./Typography/Heading";
import { WiderPageSection } from "./WiderPageSection";

const Layout = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > :not(:first-child) {
        margin-top: ${(p) => p.theme.grid.gutters.m};
    }
`;

const Ul = styled.ul`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > :not(:first-child) {
        margin-top: ${gridSquares(4)};
    }
`;

const Li = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const ValuesHeading = styled(Heading)`
    margin-bottom: 0px;
`;

const ValuesPara = styled(Paragraph)`
    margin-top: 0px;
`;

export const Values = () => (
    <WiderPageSection>
        <Layout>
            <ValuesHeading level="h2">Values</ValuesHeading>
            <Ul>
                {values.map((v) => (
                    <Li key={v[0]}>
                        <ValuesHeading level="h3" levelStyle="h4">
                            {v[0]}
                        </ValuesHeading>
                        <GridGutterRows gutters={1} />
                        <ValuesPara>{v[1]}</ValuesPara>
                    </Li>
                ))}
            </Ul>
        </Layout>
    </WiderPageSection>
);
