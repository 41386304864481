import { gridSquares } from "../styles/grid";
import { Icon } from "./Icon";
import React from "react";
import styled from "styled-components";
import { IconType, SocialMediaLink } from "../types";
import { OutboundLink } from "../components/Link";

const PADDING = gridSquares(0.5);

const Layout = styled.section`
    display: flex;
    align-items: center;
`;

const A = styled(OutboundLink)`
    padding: ${PADDING};
    transform: translateX(-${PADDING});
`;

interface PropsType {
    links: SocialMediaLink[];
}

export const SocialMediaLinks = ({ links }: PropsType) => (
    <Layout>
        {links.map((l) => {
            const iconType = l.type as any as IconType;

            return (
                <A key={l.type} href={l.url}>
                    <Icon type={iconType} size="l" />
                </A>
            );
        })}
    </Layout>
);
