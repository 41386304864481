import React from "react";
import styled from "styled-components";
import { gridSquares } from "../styles/grid";
import { Heading } from "../components/Typography/Heading";

interface RouteInterface {
    title: string;
    subtitle?: string;
}

export const RouteHeading = ({ title, subtitle }: RouteInterface) => {
    return (
        <HeadingContainer>
            <Centered>
                {title && (
                    <Title level="h1" levelStyle="h2">
                        {title}
                    </Title>
                )}
                {subtitle && <SubTitle>{subtitle}</SubTitle>}
            </Centered>
        </HeadingContainer>
    );
};

const SubTitle = styled.span`
    max-width: 600px;
    text-align: center;
    margin-top: 0.5em;
`;
const HeadingContainer = styled.div`
    padding-top: ${gridSquares(5)};
    padding-bottom: ${gridSquares(3.5)};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Centered = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled(Heading)`
    margin: 0;
    word-break: break-word;
`;
