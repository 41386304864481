import { Location } from "../Location";
import { PageSectionContainer } from "../PageSection";
import { Pledge1Summary } from "../Pledge1Summary";
import React from "react";
import { Head } from "../Head";
import { Values } from "../Values";
import { GridGutterRows } from "../GridGutters";
import { SEOOnly } from "../SEOOnly";
import { Heading } from "../Typography/Heading";
import { Team } from "../Team";
import styled from "styled-components";
import Button from "../Button";
import { RouteHeading } from "../RouteHeading";
import { COMPANY_TITLE } from "../../constants/titles";
import { ContentGroups } from "../../constants/contentGroups";

interface PropsType {
    teamMembers: GatsbyTypes.CompanyPageQuery["allSanityAuthor"]["nodes"];
}

const MyPageSection = styled(PageSectionContainer)`
    padding: 5em 1em;
    text-align: center;
    background-color: ${(p) => p.theme.color.secondaryOne.main};
    color: ${(p) => p.theme.color.secondaryOne.contrastText};
`;

const HeadingNoMargin = styled(Heading)`
    margin-bottom: 0px;
`;

export const Company = ({ teamMembers }: PropsType) => (
    <>
        <Head contentGroup={ContentGroups.company} title={COMPANY_TITLE} />
        <SEOOnly>
            <h1>Company</h1>
        </SEOOnly>
        <RouteHeading title={"About Easy Agile"}></RouteHeading>
        <Values />
        <GridGutterRows></GridGutterRows>
        <Team teamMembers={teamMembers} />
        <Pledge1Summary />
        <Location />
        <MyPageSection>
            <HeadingNoMargin level="h3" levelStyle="h3">
                We Are Growing
            </HeadingNoMargin>
            <GridGutterRows gutters={3} />
            <Button variation="lightOutline" href="/careers/">
                Join our team
            </Button>
        </MyPageSection>
    </>
);
