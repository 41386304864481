import { LINKED_IN_URL_PREFIX } from "../constants/urls";
import { IconType, SocialMediaLink } from "../types";

export const getSocialMediaLinks = (twitter: string, linkedIn: string) => {
    const socialMediaLinks: SocialMediaLink[] = [];

    if (twitter) {
        socialMediaLinks.unshift({
            type: IconType.twitter,
            url: `https://twitter.com/${twitter}`,
        });
    }

    if (linkedIn) {
        socialMediaLinks.unshift({
            type: IconType.linkedIn,
            url: `${LINKED_IN_URL_PREFIX}${linkedIn}`,
        });
    }

    return socialMediaLinks;
};
