import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { gridSquares } from "../styles/grid";

import { Paragraph } from "./Typography/Paragraph";
import React from "react";
import styled from "styled-components";
import { Heading } from "./Typography/Heading";
import { WiderPageSection } from "./WiderPageSection";

let intervalId;

const getTime = () => {
    const options = {
        timeStyle: "long",
        dateStyle: "medium",
        timeZone: "Australia/Sydney",
    } as Intl.DateTimeFormatOptions;

    const dateStr = new Date()
        .toLocaleString("en-US", options)
        .replace(/GMT\+10/, "AEST") // make more "moment-like" timezone
        .replace(/GMT\+11/, "AEDT");
    return dateStr;
};

const stopTimer = () => {
    if (intervalId) {
        clearInterval(intervalId);
    }
};

const ParentLayout = styled.section`
    width: 100%;
    padding-bottom: ${gridSquares(5)};

    display: flex;
    flex-direction: column;
    align-items: center;

    > :first-child {
        margin-bottom: ${(p) => p.theme.grid.gutters.l};
    }
`;

const TextLayout = styled.div`
    width: 100%;
    max-width: ${(p) => (p.noMaxWidth ? `100%` : p.theme.maxWidth.pageSection)};
    padding: 0 ${gridSquares(1)};

    display: flex;
    flex-direction: column;
    align-items: ${(p) => (p.noMaxWidth ? `center` : `flex-start`)};

    > :not(:first-child) {
        margin-top: ${(p) => p.theme.grid.gutters.m};
    }
`;

export const Location = () => {
    const isBrowser = typeof window !== "undefined";
    const [time, setTime] = React.useState(getTime());

    const startTimer = () => {
        intervalId = setInterval(() => {
            setTime(getTime());
        }, 60 * 1000);
    };

    React.useEffect(() => {
        startTimer();
        return stopTimer;
    }, []);

    // width & height are set to the source image dimensions
    const { wollongongImg } = useStaticQuery(
        graphql`
            {
                wollongongImg: file(name: { eq: "wollongong" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1600, height: 709, layout: FIXED)
                    }
                }
            }
        `
    );

    if (!time) {
        return;
    }

    return (
        <ParentLayout>
            <GatsbyImage
                image={wollongongImg.childImageSharp.gatsbyImageData}
                alt="A beach with a city in the background."
                style={{
                    height: gridSquares(15),
                    objectFit: `cover`,
                    objectPosition: `50% 20%`,
                    width: `100%`,
                }}
            />
            <WiderPageSection>
                <Heading level="h2">Where we work</Heading>
                <Paragraph>
                    Easy Agile is represented by people from Brighton, Victoria
                    all the way to the Gold Coast, Queensland. We were founded
                    in Wollongong, NSW and that is where our head office is
                    today, and we also have an office in Surry Hills in Sydney,
                    NSW.
                </Paragraph>

                <Paragraph>
                    Our Wollongong office is located on the traditional lands of
                    the Dharawal Speaking Country people. We acknowledge that
                    sovereignty was never ceded and pay our respects to elders
                    past, present and future.
                </Paragraph>
                <Paragraph>
                    If you want to pop by to say hi, you can find us at Level 6,
                    6-8 Regent St, Wollongong.{" "}
                    {isBrowser && <>Our current time is {time}.</>}
                </Paragraph>
                <Paragraph>
                    Hear from the founders themselves on why they founded the
                    business in Wollongong in{" "}
                    <a href="https://www.easyagile.com/blog/easy-agile-podcast-ep-11/">
                        Episode 11 of the Easy Agile podcast
                    </a>
                    .
                </Paragraph>
            </WiderPageSection>
        </ParentLayout>
    );
};
