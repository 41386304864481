import { Link } from "gatsby";
import { Paragraph } from "./Typography/Paragraph";
import React from "react";
import { GridGutterRows } from "./GridGutters";
import { Heading } from "./Typography/Heading";
import { WiderPageSection } from "./WiderPageSection";
import styled from "styled-components";

const PledgePara = styled(Paragraph)`
    margin-top: 0px;
`;

export const Pledge1Summary = () => (
    <WiderPageSection>
        <GridGutterRows gutters={1} />
        <Heading level="h2">Commitment to Pledge 1%</Heading>
        <PledgePara>
            Easy Agile has made a commitment to Pledge 1%. We donate 1% of team
            member time, product licenses and profit to charitable causes.{" "}
            <br />
            <Link to="/pledge/">Find out more</Link>
        </PledgePara>
        <GridGutterRows gutters={1} />
    </WiderPageSection>
);
