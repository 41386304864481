const values = [
    [
        "Better with balance",
        "Being in balance is how we maintain our sustainable pace and energy levels. This allows to be better at providing amazing customer outcomes, consistently. We embrace being human and that maintaining our balance is a dynamic and personal process, so we allow our team members to be flexible in when and how they work.",
    ],
    [
        "Give back",
        "We love to actively participate in our communities. Whether it's showing up, lending a hand, teaching a skill or donating dollars, Easy Agile gives back.",
    ],
    [
        "Be the customer",
        "Empathising with our customers gives us the understanding to deliver solutions that exceed their expectations.",
    ],
    [
        "Punch above our weight",
        "We are a lean team, focused on working smart and delivering high impact solutions. We experiment knowing it is okay to fail as it gives us the opportunity to learn.",
    ],
    [
        "Engage system 2",
        "We do the hard work to make life easier for our customers. We take the time to understand before we execute and we strive to be effective.",
    ],
    [
        "Commit, as a team",
        "We grow and succeed when we do it together. We commit to bringing our whole selves to work, to looking after one another, and to engaging with authenticity and courage.",
    ],
];

export default values;
