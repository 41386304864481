import { gridSquares } from "../../styles/grid";
import styled, { css } from "styled-components";
import {
    bold12Type,
    regular10Type,
    regular12Type,
} from "../../styles/typography";

export const ParentLayout = styled.section`
    width: 100%;

    padding: ${(p) => p.theme.grid.gutters.l} 0;
    display: flex;
    justify-content: center;
`;

export const ChildLayout = styled.div`
    width: 100%;
    max-width: ${(p) => p.theme.maxWidth.teamMembersSection};

    display: flex;
    flex-direction: column;

    > :nth-child(2) {
        margin-top: ${(p) => p.theme.grid.gutters.m};
    }

    > :nth-child(4) {
        margin-top: ${gridSquares(2)};
    }

    @media (max-width: ${(p) => p.theme.maxWidth.teamMembersSection}) {
        max-width: ${(p) => p.theme.maxWidth.pageSection};
    }
`;

export const TeamButtonUl = styled.ul`
    width: 100%;

    padding: 0 ${(p) => p.theme.grid.gutters.s} ${(p) => p.theme.grid.gutters.s};
    display: flex;
    flex-wrap: wrap;
`;

export const Line = styled.div`
    width: calc(100% - 2 * ${(p) => p.theme.grid.gutters.s});

    align-self: center;
    border-bottom: 2px solid ${(p) => p.theme.color.divider.main};
`;

export const TeamButtonLi = styled.li`
    display: flex;
`;

export const TeamButton = styled.button<{ isSelected: boolean }>`
    height: ${gridSquares(3)};
    padding: 0 ${(p) => p.theme.grid.gutters.s};
    border-radius: ${(p) => p.theme.borderRadius.s};

    :hover {
        background: ${(p) => p.theme.color.temp.buttonHoverBackground};
    }

    ${(p) =>
        p.isSelected &&
        css`
            background: ${p.theme.color.primary.main};

            :hover {
                background: ${p.theme.color.primary.main};
            }
        `}
`;

export const TeamButtonText = styled.span<{ isSelected: boolean }>`
    ${regular12Type};
    word-wrap: nowrap;

    ${(p) =>
        p.isSelected &&
        css`
            ${bold12Type};
            color: ${p.theme.color.primary.contrastText};
        `}
`;

export const AddPadding = styled.div`
    width: 100%;

    padding: ${gridSquares(1)} ${(p) => p.theme.grid.gutters.s};
    display: flex;
`;

export const PhotoList = styled.ul`
    width: 100%;
    padding: 0 calc(${(p) => p.theme.grid.gutters.s} * 0.5);

    display: flex;
    flex-wrap: wrap;
`;

export const PhotoLi = styled.li`
    display: flex;
    margin: calc(${(p) => p.theme.grid.gutters.s} * 0.5);

    :hover {
        outline: none;
        img {
            transform: scale(1.1);
        }
    }
`;

export const PhotoButton = styled.button`
    width: ${gridSquares(9)};

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    text-align: left;

    :hover {
        outline: none;
        img {
            transform: scale(1.1);
        }
    }

    > :not(:first-child) {
        margin-top: ${gridSquares(0.5)};
    }
`;

export const PhotoText = styled.p`
    width: 100%;

    ${regular10Type}
    color: ${(p) => p.theme.color.background.contrastText};
    word-wrap: wrap;
`;
