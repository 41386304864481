import { graphql } from "gatsby";
import React from "react";
import { Company } from "../components/Company";

const CompanyPage = ({
    data: { allSanityAuthor },
}: {
    data: GatsbyTypes.CompanyPageQuery;
}) => {
    return <Company teamMembers={allSanityAuthor.nodes} />;
};

export const pageQuery = graphql`
    query CompanyPage {
        allSanityAuthor(
            sort: { order: ASC, fields: startDate }
            filter: { inactive: { ne: true } }
        ) {
            nodes {
                team
                name
                startDate
                title
                twitter
                linkedIn
                _rawDescription
                _id
                image {
                    asset {
                        gatsbyImageData(layout: CONSTRAINED, width: 164)
                    }
                }
            }
        }
    }
`;

export default CompanyPage;
